import type {ReactNode} from 'react';
import React from 'react';

import Section from '@/components/pages/oberlo/components/Section/Section';
import Article from '@/components/pages/oberlo/components/Article/Article';
import {ShopifyBanner} from '@/components/pages/oberlo/components/Banner/Banner';
import {useTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import type {
  ShopifyOberloData,
  OberloTopic,
} from '@/components/pages/oberlo/types';
import {getShopifyArticleData} from '@/components/pages/oberlo/Home/utils/GetArticleData';
import {getAuthor} from '@/components/pages/oberlo/utils/utils';
import {paginatorLastElement} from '@/components/pages/oberlo/styles/styles';

import {IMAGE_TAB_INDEX} from '../../constants/index';

interface LatestArticlesListProps {
  articlesData: ShopifyOberloData[];
  className?: string;
  showLatest?: boolean;
  children?: ReactNode;
  isLastPage: boolean;
  topics: OberloTopic[];
}

export default function LatestArticlesList({
  articlesData,
  showLatest = true,
  children,
  isLastPage,
  topics,
}: LatestArticlesListProps) {
  const {t} = useTranslations();
  const {site} = useSiteData();
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';
  const articles = articlesData?.map((article) =>
    getShopifyArticleData({articleData: article, topics, lang}),
  );

  return (
    <Section className="container w-full mx-auto px-8 lg:px-[5.625rem] grid grid-cols-1 gap-8 md:grid-cols-12 md:gap-12">
      <div className="px-8 md:px-0 md:col-span-2">{children}</div>
      <ul className="px-8 md:px-0 md:col-span-10 lg:col-span-8 divide-y divide-gray-30 md:divide-y-0 md:space-y-16 pt-0 md:pt-12">
        {articles.map((article, index) => (
          <React.Fragment key={`${article.title}-${index}`}>
            <li
              key={article.title}
              className={
                !isLastPage && index === articles.length - 1
                  ? paginatorLastElement
                  : ''
              }
            >
              <Article
                className="hidden md:flex md:flex-row-reverse py-0 md:justify-end"
                blogTitle={t(`${article.type}Title`)}
                blogLink={article.blogLink}
                articleLink={article.articleLink}
                articleTitle={article.articleTitle}
                title={article.title}
                titleLink={article.titleLink}
                description={article.description}
                author={
                  article.type !== 'podcast' && article.type !== 'video'
                    ? article.author
                    : ''
                }
                imageProps={
                  article.imageProps
                    ? {
                        ...article.imageProps,
                        src: article?.imageProps?.src || '',
                        tabIndex: IMAGE_TAB_INDEX,
                        loading: 'lazy',
                        linkStyles: 'ml-12 mt-6 w-40 h-20',
                        pictureStyles:
                          'bg-cover overflow-hidden relative w-40 h-20',
                        className:
                          'transform hover:scale-110 transition-transform ease-in duration-200 w-full h-full object-cover',
                      }
                    : undefined
                }
              />
              {article.articleLink && (
                <Article
                  className="flex flex-row-reverse md:hidden py-8"
                  blogTitle={t(`${article.type}Title`)}
                  blogLink={article.blogLink}
                  articleLink={article.articleLink}
                  articleTitle={article.articleTitle}
                  title={article.title}
                  titleLink={article.titleLink}
                  description={article.description}
                  author={getAuthor(article)}
                />
              )}
            </li>
            {index === 2 && showLatest && (
              <li key="banner-blog-index" className="banner">
                <ShopifyBanner className="md:mx-0" />
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </Section>
  );
}
